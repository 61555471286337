.mobile-header-01 {
    padding: 10px 15px;
    box-shadow: var(--#{$prefix}box-shadow-sm);
    .header-transparent.fixed-header & {
        background-color: var(--#{$prefix}bg-mode-re);
    }
    .header-transparent:not(.fixed-header) & {
        box-shadow: none;
    }
    .mob-head-in {
        display: flex;
        align-items: center;
    }

    .hm-toggle-mob {
		width: 40px;
		height: 40px;
		border: none;
		background: none;
		outline: none;
		position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
		span {
			position: absolute;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
            color: var(--#{$prefix}text-mode);
		    background: currentColor;
		    box-shadow: 0 -7px 0 currentColor, 0 7px 0 currentColor;
		    width: 24px;
		    height: 2px;
		    margin: auto;
            .header-transparent:not(.fixed-header) & {
                color: var(--#{$prefix}white);
            }
		}
	}

    .mob-logo {
        flex: 1;
        a {
            display: flex;
        }
    }

    .mob-end {
        .nav-link {
            padding: 0;
            height: 40px;
            width: 40px;
            font-size: 22px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: var(--#{$prefix}text-mode);
            .header-transparent:not(.fixed-header) & {
                color: var(--#{$prefix}white);
            }
            sub {
                position: absolute;
                top: 1px;
                right: 1px;
                font-size: 9px;
                min-width: 18px;
                height: 18px;
                background: var(--#{$prefix}primary);
                line-height: 1;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: var(--#{$prefix}white);
            }
        }
    }
}


.mob-header-btn-fixed-01 {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: var(--#{$prefix}body-bg);
    box-shadow: var(--#{$prefix}box-shadow-sm);
    ~ .footer {
        @include media-breakpoint-down(lg) {
            margin-bottom: 58px;
        }
    }
    .mob-hb-in {
        display: flex;
    }
    .mob-hb-item {
        flex: 1 0 20%;
    }
    .mob-hb-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--#{$prefix}text-mode);
        padding: 8px 0;
        i {
            font-size: 22px;
            line-height: 1;
            position: relative;
        }
        span {
            font-size: 12px;
        }
        sub {
            position: absolute;
            top: -5px;
            right: -9px;
            bottom: auto;
            font-size: 9px;
            min-width: 18px;
            height: 18px;
            background: var(--#{$prefix}primary);
            line-height: 1;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: var(--#{$prefix}white);
            font-family: var(--#{$prefix}body-font-family);
        }
    }
}


.mobile-nav-offcanvas {
 
    max-width: 320px;
    @include mob_menu_icon();
    @include mobile_menu();

    .offcanvas-header {
        padding: 15px;
        flex-direction: column;
        position: relative;
        align-items: flex-start;
        background: #000;
        gap: 10px;
        z-index: 1;
        .btn-close {
            border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1) !important;
            background: rgba(var(--#{$prefix}white-rgb), 0.1);
            position: absolute;
            top: 10px;
            right: 10px;
            width: 35px;
            height: 35px;
            padding: 0;
            margin: 0;
            color: var(--#{$prefix}white);
            opacity: 1;
            filter: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            border-radius: 5px;
        }
        .mob-user {
            a {
                display: flex;
                align-items: center;
                color: var(--#{$prefix}white);
                i {
                    width: 35px;
                    height: 35px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--#{$prefix}primary);
                    border-radius: 5px;
                    margin-right: 10px;
                    font-size: 16px;
                }
            }
        }
        .mob-extra {
            margin: 0;
            padding: 0;
            display: flex;
            gap: 12px;
            list-style: none;
            .nav-link {
                border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1) !important;
                background: rgba(var(--#{$prefix}white-rgb), 0.1);
                color: var(--#{$prefix}white);
                padding: 6px 10px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 13px;
            }
        }
    }
    .offcanvas-header-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        pointer-events: none;
        z-index: -1;
        &:after,
        &:before {
            content: "";
            position: absolute;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            filter: blur(50px);
        }
        &:after {
            bottom: 0;
            right: 0;
            background: #FBB82A;
        }
        &::before {
            top: 0;
            left: 0;
            background: #3A86FF;
        }
    }

    .offcanvas-body {
        padding: 0;
        .dropdown-menu {
            box-shadow: none !important;
            border-bottom: none;
            border-left: none;
            border-right: none;
            padding: 0;
        }
    }
}