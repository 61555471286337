/* Product Card 8
-------------------------------------------*/
.product-card-9 {
	.product-card-image {
		position: relative;
	}
	.badge-ribbon {
		position: absolute;
		top: 10px;
		left: 10px;
		width: 45px;
		height: 45px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		text-transform: uppercase;
		background: var(--#{$prefix}danger);
		color: var(--#{$prefix}white);
		z-index: 1;
		border-radius: 50%;
	}
	.product-media {
		position: relative;
		overflow: hidden;
		img {
			transition: ease all 0.35s;
			transform: scale(1);
			opacity: 1;
		}
		.product-media-hover {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: ease all 0.35s;
			transform: scale(1);
			opacity: 0;
		}
	}
	.product-action {
		position: absolute;
		display: flex;
		flex-direction: column;
		z-index: 1;
		top: 10px;
		right: 0;
		opacity: 0;
		transition: ease all 0.35s;
		.btn {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			padding: 0;
			box-shadow: 0 5px 10px rgba(var(--#{$prefix}dark-rgb), .05);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: var(--#{$prefix}white);
			color: var(--#{$prefix}dark);
			border: 1px solid var(--#{$prefix}gray-300);
			+ .btn {
				margin-top: 6px;
			}
			&:hover {
				background: var(--#{$prefix}primary);
				color: var(--#{$prefix}white);
			}
		}
	}
	.product-card-info {
	    text-align: center;
		padding: 10px 0 0;
	}
	.rating-star {
		font-size: 12px;
		margin-bottom: 5px;
	}
	.product-title {
		font-size: 14px;
		margin: 0;
		a {
			color: var(--#{$prefix}text-mode);
		}
	}
	.nav-thumbs {
	    margin-top: 8px;
	    font-size: 0px;
	    line-height: normal;
		.form-check {
			min-height: inherit;
			margin: 0 1px;
		}
	}
	.product-price {
		margin-top: 8px;
	}
	&:hover {
		.product-action {
			right: 10px;
			opacity: 1;
		}
		.product-media {
			img {
				transform: scale(1.03);
				opacity: 0;
			}
			.product-media-hover {
				transform: scale(1.03);
				opacity: 1;
			}
		}
	}
}