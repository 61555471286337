/* Product Card 7
-------------------------------------------*/
.product-card-7 {
	.product-card-image {
		position: relative;
	}
	.badge-ribbon {
		position: absolute;
		top: 10px;
		left: 0;
		z-index: 1;
	}
	.product-action {
		position: absolute;
	    display: flex;
	    flex-direction: column;
	    right: 10px;
	    top: 10px;
	    z-index: 2;
	    
	    transition: ease opacity 0.35s;
	    .btn {
    	    padding: 0;
		    border: 1px solid var(--#{$prefix}gray-300);
		    width: 30px;
		    height: 30px;
		    line-height: 1;
		    display: inline-flex;
		    align-items: center;
		    justify-content: center;
		    color: var(--#{$prefix}text-mode);
		    background: var(--#{$prefix}body-bg);
		    font-size: 14px;
		    border-radius: 50%;
		    + .btn {
		    	margin-top: 8px;
		    }
		    &:hover {
		    	background: var(--#{$prefix}primary);
		    	border-color: var(--#{$prefix}primary);
		    	color: var(--#{$prefix}white);
		    }
	    }
	}
	.product-media {
		overflow: hidden;
		border-radius: 5px;
		border: 1px solid var(--#{$prefix}gray-300);
		img {
			transition: transform 1.1s cubic-bezier(.15,.75,.5,1) 0s;
			transform: translateZ(0);
		}
	}

	.product-cart-btn {
		padding-top: 0.7rem;
		.btn {
			width: 100%;
			border-radius: 3px;
			font-size: 14px;
		}
	}

	.product-card-info {
		padding-top: 15px;
		text-align: center;
	}

	.product-title {
		font-weight: 600;
		margin: 0 0 6px;
		a {
			color: var(--#{$prefix}text-mode);
			&:hover {
				color:var(--#{$prefix}primary);
			}
		}
	}
	.product-price {
		span {
			font-size: 16px;
			font-weight: 600;
		}
		del {
			font-size: 14px;
		}
	}
	.rating-star {
		font-size: 12px;
		margin-bottom: 5px;
	}

	&:hover {
		.product-media {
			img {
				transform: scale3d(1.1,1.1,1.1) translateZ(0);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.product-action {
			opacity: 0;
			.btn {
				width: 40px;
		    	height: 40px;
		    	font-size: 18px;
				i {
			    	transform: scale(0);
			    	transition: ease transform 0.35s;
			    }
			}
		}
		.product-cart-btn {
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
			padding: 1rem;
			opacity: 0;
			bottom: -15px;
			transition: ease all 0.35s;
		}
		&:hover {
			.product-cart-btn {
				opacity: 1;
				bottom: 0px;
			}
			.product-action {
				opacity: 1;
				.btn {
					i {
						transform: scale(1);
					}
				}
			}
		}
	}
}