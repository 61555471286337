@import "../vendor/bootstrap/scss/mixins/banner";
@include bsBanner("");



// scss-docs-start import-stack
// Bootstrap Configuration
@import '../vendor/bootstrap/scss/functions';

// Custom setup
@import 'core/custom/user-variables';

// Theme
@import "core/variables";
@import "core/variables-dark";
@import "core/components/utilitie";

// Theme
@import "core/fonts";

@import "../vendor/bootstrap/scss/variables";
@import "../vendor/bootstrap/scss/variables-dark";
@import "../vendor/bootstrap/scss/maps";
@import "../vendor/bootstrap/scss/mixins";
@import "../vendor/bootstrap/scss/utilities";






// Bootstrap Layout & components
@import "../vendor/bootstrap/scss/root";
@import 'core/root';
@import "../vendor/bootstrap/scss/reboot";
@import "../vendor/bootstrap/scss/type";
@import "../vendor/bootstrap/scss/images";
@import "../vendor/bootstrap/scss/containers";
@import "../vendor/bootstrap/scss/grid";
@import "../vendor/bootstrap/scss/tables";
@import "../vendor/bootstrap/scss/forms";
@import "../vendor/bootstrap/scss/buttons";
@import "../vendor/bootstrap/scss/transitions";
@import "../vendor/bootstrap/scss/dropdown";
@import "../vendor/bootstrap/scss/button-group";
@import "../vendor/bootstrap/scss/nav";
@import "../vendor/bootstrap/scss/navbar";
@import "../vendor/bootstrap/scss/card";
@import "../vendor/bootstrap/scss/accordion";
@import "../vendor/bootstrap/scss/breadcrumb";
@import "../vendor/bootstrap/scss/pagination";
@import "../vendor/bootstrap/scss/badge";
@import "../vendor/bootstrap/scss/alert";
@import "../vendor/bootstrap/scss/progress";
@import "../vendor/bootstrap/scss/list-group";
@import "../vendor/bootstrap/scss/close";
@import "../vendor/bootstrap/scss/toasts";
@import "../vendor/bootstrap/scss/modal";
@import "../vendor/bootstrap/scss/tooltip";
@import "../vendor/bootstrap/scss/popover";
@import "../vendor/bootstrap/scss/carousel";
@import "../vendor/bootstrap/scss/spinners";
@import "../vendor/bootstrap/scss/offcanvas";
@import "../vendor/bootstrap/scss/placeholders";

// Helpers
@import "../vendor/bootstrap/scss/helpers";

// Utilities
@import "../vendor/bootstrap/scss/utilities/api";
// scss-docs-end import-stack





// ---------- Vendor ---------- //
@import "../vendor/bootstrap/icon/bootstrap-icons.css";
@import "../vendor/magnific/magnific-popup.css";
@import "../vendor/swiper/swiper-bundle.min.css";
@import "../vendor/feather/feather.css";




// Header
@import "core/header/header";


// Footer
@import "core/footer/footer";



// Base
@import "core/components/wrapper";
@import "core/components/buttons";
@import "core/components/spinner";
@import "core/components/icon";
@import "core/components/section";
@import "core/components/article";
@import "core/components/avatar";
@import "core/components/dropdown";
@import "core/components/link";
@import "core/components/color";
@import "core/components/background";
@import "core/components/swiper";
@import "core/components/form";
@import "core/components/count-down";
@import "core/components/my-account";
@import "core/components/list";
@import "core/components/hover";
@import "core/components/titles";
@import "core/components/tab";
@import "core/components/img";
@import "core/components/table";
@import "core/components/device";
@import "core/components/breadcrumb";
@import "core/components/container";
@import "core/components/rating-star";
@import "core/components/scroll";



// Product Card 
@import "core/product-card/product-card-01";
@import "core/product-card/product-card-02";
@import "core/product-card/product-card-03";
@import "core/product-card/product-card-04";
@import "core/product-card/product-card-05";
@import "core/product-card/product-card-06";
@import "core/product-card/product-card-07";
@import "core/product-card/product-card-08";
@import "core/product-card/product-card-09";
@import "core/product-card/product-card-10";


//Category Card
@import "core/category-card/category-card-01";


// Shop 
@import "core/shop/shop-clear-filter-01";
@import "core/shop/shop";
@import "core/shop/shop-02";
@import "core/shop/shop-03";
@import "core/shop/shop-04";


// Product Detail 
@import "core/product-detail/product-detail";
@import "core/product-detail/product-detail-02";
@import "core/product-detail/product-detail-03";




// Custom styles (User's file)
@import "core/custom/styles";
