// All-caps `RGBA()` function used because of this Sass bug: https://github.com/sass/node-sass/issues/2251
@each $color, $value in $grays {
    $color-rgb: to-rgb($value);
    .bg-gray-#{$color} {
        background-color: var(--#{$prefix}gray-#{$color}) !important;
    }
    .border-gray-#{$color} {
        border-color: var(--#{$prefix}gray-#{$color}) if($enable-important-utilities,  !important, null);
    }
}

.bg-mode {
    background-color: var(--#{$prefix}bg-mode);
}

.bg-mode-re {
    background-color: var(--#{$prefix}bg-mode-re);
}

.bg-cover {
	background-size: cover;
}
.bg-right-center {
    background-position: center right;
}
.bg-50 {
    background-position: 50%;
}

.bg-no-repeat {
	background-repeat: no-repeat;
}

.bg-center {
	background-position: center;
}

.bg-black {
	background: var(--#{$prefix}black);
}


@include media-breakpoint-up(lg) {
  .bg-fixed {
    background-attachment: fixed;
  }
}