.radio-text {
	position: relative;
	padding: 0;
    .radio-text-label {
		min-width: 2rem;
		height: 2rem;
	    font-size: .75rem;
	    border: 1px solid var(--#{$prefix}gray-300);
	    padding: 0;
	    display: inline-flex;
	    align-items: center;
	    justify-content: center;
	    cursor: pointer;
    }
    .form-check-input {
    	position: absolute;
    	top: 0;
    	left: 0;
    	right: 0;
    	bottom: 0;
    	opacity: 0;
    	margin: 0;
    	&:checked {
    		~ .radio-text-label {
    			border-color: var(--#{$prefix}primary);
    			color: var(--#{$prefix}primary);
    		}
    	}
    }
}


.radio-color {
	position: relative;
	padding: 0;
    vertical-align: top;
    font-size: 0;
    .radio-color-label {
		width: 1.25rem;
		height: 1.25rem;
	    font-size: .75rem;
	    border: 1px solid var(--#{$prefix}gray-300);
	    padding: 0;
	    display: inline-flex;
	    align-items: center;
	    justify-content: center;
	    cursor: pointer;
	    border-radius: 50%;
	    position: relative;
	    span {
	    	position: absolute;
	    	top: 1px;
	    	left: 1px;
	    	right: 1px;
	    	bottom: 1px;
	    	border-radius: 50%;
	    }
    }
    .form-check-input {
    	position: absolute;
    	top: 0;
    	left: 0;
    	right: 0;
    	bottom: 0;
    	opacity: 0;
    	margin: 0;
    	&:checked {
    		~ .radio-color-label {
    			border-color: var(--#{$prefix}primary);
    			color: var(--#{$prefix}primary);
    		}
    	}
    }
    &.large {
        .radio-color-label {
            width: 1.85rem;
            height: 1.85rem;
        }
    }
}


.custom-checkbox {
    position: relative;
    .custom-control-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        padding-left: 2rem;
        &:before {
            content: "";
            position: absolute;
            top: .1875rem;
            left: 0;
            display: block;
            width: 1.125rem;
            height: 1.125rem;
            pointer-events: none;
            background-color: var(--#{$prefix}gray-300);
            border: 1px solid var(--#{$prefix}gray-200);
        }
        &:after {
            content: "";
            position: absolute;
            border-top: 2px solid var(--#{$prefix}text-mode-re);
            border-right: 2px solid var(--#{$prefix}text-mode-re);
            width: 9px;
            height: 5px;
            top: -4px;
            left: 4px;
            z-index: 1;
            bottom: 0;
            margin: auto;
            transform: rotate(136deg);
            opacity: 0;
        }
    }   
    .custom-control-input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1.125rem;
        height: 1.3125rem;
        opacity: 0;
        &:checked {
            ~ .custom-control-label {
                &:before {
                    background-color: var(--#{$prefix}text-mode);
                }
                &:after {
                    opacity: 1;
                }
            }
        }
        &:disabled, 
        &[disabled] {
            ~ .custom-control-label {
                text-decoration: line-through;
                &:before {
                    border-color: var(--#{$prefix}gray-200);
                    background-color: var(--#{$prefix}gray-200)
                }
            }
        }
    }
    /*color*/
    &.checkbox-color {
        .custom-control-label {
            &:before {
                background-color: currentColor;
            }
        }
        .custom-control-input {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 1.125rem;
            height: 1.3125rem;
            opacity: 0;
            &:checked {
                ~ .custom-control-label {
                    &:before {
                        background-color: currentColor;
                    }
                    &:after {
                        opacity: 1;
                    }
                }
            }
            &:disabled, 
            &[disabled] {
                ~ .custom-control-label {
                    text-decoration:none;
                    span {
                        text-decoration: line-through;   
                    }
                }
            }
        }
    }
    /*Color*/
}


.custom-radio {
    position: relative;
    .custom-control-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        padding-left: 2rem;
        &:before {
            content: "";
            position: absolute;
            top: .1875rem;
            left: 0;
            display: block;
            width: 1.125rem;
            height: 1.125rem;
            pointer-events: none;
            background-color: var(--#{$prefix}gray-300);
            border: none;
            border-radius: 50%;
        }
        &:after {
            content: "";
            position: absolute;
            border-top: 2px solid var(--#{$prefix}text-mode-re);
            border-right: 2px solid var(--#{$prefix}text-mode-re);
            width: 9px;
            height: 5px;
            top: -4px;
            left: 4px;
            z-index: 1;
            bottom: 0;
            margin: auto;
            transform: rotate(136deg);
            opacity: 0;
        }
    }   
    .custom-control-input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1.125rem;
        height: 1.3125rem;
        opacity: 0;
        &:checked {
            ~ .custom-control-label {
                &:before {
                    background-color: var(--#{$prefix}text-mode);
                }
                &:after {
                    opacity: 1;
                }
            }
        }
        &:disabled, 
        &[disabled] {
            ~ .custom-control-label {
                &:before {
                    border-color:var(--#{$prefix}gray-300);
                    background-color:var(--#{$prefix}gray-300);
                }
            }
        }
    }
}


.shop-list-tags {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 3px;
        margin-bottom: 3px;
        border-radius: 3px;
        padding: 6px 15px;
        background: var(--#{$prefix}gray-100);
        color: var(--#{$prefix}body-color);
        font-size: 14px;
        border: 1px solid var(--#{$prefix}gray-100);
        position: relative;
        &.active,
        &:hover {
            border: 1px solid var(--#{$prefix}primary);
            background: var(--#{$prefix}white);
            color: var(--#{$prefix}primary);
        }
    }
}

.shop-list-colors {
    display: flex;
    flex-wrap: wrap;
    a {
        font-size: 0px;
        flex: 0 0 30px;
        max-width: 30px;
        height: 30px;
        box-shadow: 0 0 0 1px var(--#{$prefix}white), 0 0 0 3px var(--#{$prefix}gray-300);
        border-radius: 50%;
        margin: 0 10px 10px 0;
        span {
            display: none;
        }
        &:hover,
        &.active {
            box-shadow: 0 0 0 1px var(--#{$prefix}white), 0 0 0 3px var(--#{$prefix}dark);
        }
    }
}

.shop-list-tags-02 {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 3px;
        margin-bottom: 3px;
        padding: 6px 15px;
        background: var(--#{$prefix}body-bg);
        color: var(--#{$prefix}text-mode);
        font-size: 14px;
        border: 1px solid var(--#{$prefix}gray-300);
        position: relative;
        &.active,
        &:hover {
            border: 1px solid var(--#{$prefix}text-mode);
            background: var(--#{$prefix}text-mode);
            color: var(--#{$prefix}text-mode-re);
        }
    }
}